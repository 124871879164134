import React, { Component } from 'react';
import '../../style/commissions/CommissionServiceSection.css';
import { Container, Header, List } from 'semantic-ui-react';

class CommissionServiceSection extends Component {
  render() {
    return (
      <Container className='commissions-info-container'>
        <div className='commission-section'>
          <Header as='h4' className='commission-section-header'>Services</Header>
          <List bulleted className='commission-section-list'>
            <List.Item>Basic Assembly</List.Item>
            <List.Item>Soldering & Desoldering</List.Item>
            <List.Item>
              Stabilizer Tuning
              <List.List>
                <List.Item>Clipped/lubed</List.Item>
                <List.Item>Krytox 205G0</List.Item>
                <List.Item>Trybosis 3204</List.Item>
                <List.Item>Dielectrtic grease for wires or lube of choice</List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              Switch Modding
              <List.List>
                <List.Item>Switch film installation</List.Item>
                <List.Item>Krytox 205G0</List.Item>
                <List.Item>Trybosis 3204</List.Item>
              </List.List>
            </List.Item>
          </List>
        </div>
        <div className='commission-section'>
          <Header as='h4' className='commission-section-header'>Process</Header>
          <List ordered className='commission-section-list'>
            <List.Item>
              Obtain all necessary components
              <List.List>
                <List.Item>Keyboard case</List.Item>
                <List.Item>PCB</List.Item>
                <List.Item>Plate</List.Item>
                <List.Item>Screws</List.Item>
                <List.Item>Stabilizers</List.Item>
                <List.Item>Switches</List.Item>
                <List.Item>Keycaps (optional)</List.Item>
              </List.List>
            </List.Item>
            <List.Item>You must cover shipping costs</List.Item>
            <List.Item>Once it's your turn, you will be contacted privately to discuss chosen service</List.Item>
            <List.Item>Item will only be shipped out after payment is recieved</List.Item>
          </List>
        </div>
      </Container>
    );
  }
}

export default CommissionServiceSection;
