import React, { Component } from 'react';
import '../style/commissions/Commissions.css';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Container, Header, Button } from 'semantic-ui-react';
import CommissionServiceSection from '../components/commissions/CommissionServiceSection';
import PricingTileSection from '../components/commissions/PricingTileSection';
import CommissionContactSection from '../components/commissions/CommissionContactSection';

class CommissionsPage extends Component {
  constructor(){
    super()
      this.state = {
        commissionsOpen: false
      }
  }

  componentDidMount() {
    this.initGA()
    this.logPageView()
  }

  initGA = () => {
    ReactGA.initialize('UA-186178302-1')
  }

  logPageView = () => {
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
  }

  render() {
    const { commissionsOpen } = this.state;

    return (
      <Container className='commissions-container'>
        <Helmet>
          <title>Commissions - Saintkeebs</title>
        </Helmet>
        <Container textAlign='center' className='commissions-header-container'>
          <Header as='h2' className='commissions-header'>Commissions</Header>
        </Container>
        <Container textAlign='center' className='commissions-intro'>
          <p>
            Here you will find all of the available services that I offer as well as pricing. At the bottom of the page, you will find a 
            Google form that you can fill out of you are interested in using my services IF the commissions status is open, if it is closed, 
            the form will be unavailable until the next announcement. Please read all information on this page carefully before submitting 
            anything to avoid confusion.
          </p>
        </Container>
        <Container textAlign='center' className='contact-intro'>
          <p>
            Please read this page thoroughly before inquiring about prices. For general questions regarding
            keyboards feel free to reach out to me via Instagram, Discord, or email.
          </p>
        </Container>
        <CommissionContactSection />
        <Container textAlign='center' className='send-email-container'>
          <a href={`mailto:saintkeebs@gmail.com`}>
            <Button className='send-email-button'>Send an Email</Button>
          </a>
        </Container>
        <Container textAlign='center' className='commissions-status-container'>
          <Header as='h3' className='commissions-status-header'>Commission Status:</Header>
          <Header as='h3' className='commissions-status'>{commissionsOpen ? "Open" : "Closed"}</Header>
        </Container>
        <CommissionServiceSection />
        <Container textAlign='center' className='pricing-header-container'>
          <Header as='h3' className='pricing-header'>Pricing</Header>
        </Container>
        <Container textAlign='center' className='pricing-intro'>
          <p>
            Pricing is based on the amount of work involved with each service. Customers should expect rates ranging below, assuming 
            all necessary components are sent and ready for assembly.
          </p>
        </Container>
        <PricingTileSection commissionsOpen={commissionsOpen} />
        {commissionsOpen
          ? (
            <>
              <Container textAlign='center' className='google-form-header-container'>
                <Header as='h3' className='google-form-header'>Google Form</Header>
              </Container>
              <Container textAlign='center' className='google-form-intro'>
                <p>
                  Lastly, the Google form below is where you can submit a request for any service listed above. Please read everything so you 
                  know how it works. Services are in first-come, first-served basis, I will reach out to you after the form has been submitted 
                  to discuss details.
                </p>
              </Container>
              <Container textAlign='center' className='google-form-container'>
                <Header as='h3' className='commissions-status-header'>
                  Click
                  <a
                    href='//docs.google.com/forms/d/e/1FAIpQLSdfVx4HUtyYgmvNXXgsb2DURL-7ehzsrfGdqRifXSRo94WnIQ/viewform'
                    target='_blank'
                    rel='noreferrer'
                  >
                    here
                  </a>
                  to fill out the service form.
                </Header>
              </Container>
            </>
          ) : null
        }
      </Container>
    );
  }
}

export default CommissionsPage;
