import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../style/portfolio/PortfolioList.css';
import firebase from 'firebase/app';
import 'firebase/database';
import { Icon, Container, Image } from 'semantic-ui-react';

class PortfolioFetch extends Component {
  constructor(){
    super()
      this.state = {
        keyboardList: [],
        isLoading: false
      }
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    const rootRef = firebase.database().ref().child('keyboards');
    rootRef.on('value', snapshot => {
      this.setState({
        keyboardList: snapshot.val(),
        isLoading: false
      })
    })
  }

  render() {
    const { keyboardList, isLoading } = this.state;
    const notHiddenLength = Object.values(keyboardList).filter(k => !k.hidden).length;

    if (isLoading) {
      return (
        <div className='portfolio-loading-icon-container'>
          <Icon loading name='keyboard outline' size='huge' className='loading-icon' />
        </div>
      )
    }

    return (
      <Container className='portfolio-list-container'>
        {Object.values(keyboardList)
          .sort((a, b) => (a.id > b.id) ? 1 : -1)
          .reverse()
          .map(keyboard =>
            !keyboard.hidden && (
              <Link
                to={'/keyboards/' + keyboard.slug}
                key={keyboard.id}
                id={keyboard.slug}
                className={notHiddenLength % 3 ? 'portfolio-card-with-space portfolio-card' : 'portfolio-card'}
              >
                <Image size='medium' src={keyboard.images[0]} />
                <div className='portfolio-card-heading-container'>
                  <h4 className='portfolio-card-heading'>{keyboard.name}</h4>
                </div>
              </Link>
            )
          )
        }
      </Container>
    );
  }
}

export default PortfolioFetch;

