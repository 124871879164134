import React, { Component } from 'react';
import '../style/home/Home.css';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Container, Header } from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import HomeTileSection from '../components/home/HomeTileSection';

class HomePage extends Component {
  componentDidMount() {
    this.initGA()
    this.logPageView()
  }

  initGA = () => {
    ReactGA.initialize('UA-186178302-1')
  }

  logPageView = () => {
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
  }

  render() {
    return (
      <Container className='home-container'>
        <Helmet>
          <title>Home - Saintkeebs</title>
        </Helmet>
        <Container textAlign='center' className='home-header-container'>
          <Header as='h2' className='home-header'>Welcome</Header>
        </Container>
        <Container textAlign='center' className='home-intro'>
          <p>
            Hello, welcome to Saintkeebs! My name is Ryan St. Germain and I am a software engineer that enjoys mechanical keyboards. 
            I have been into building keyboards for a little over a year and my goal with this is to give this hobby more exposure. Here on 
            my website, you can find my portfolio of all the different keyboards that I have built. My commissions page is a place where you
            can see the different services I offer and a Google form to start your next mechanical keyboard build.
          </p>
        </Container>
        <HomeTileSection />
        <Container textAlign='center' className='home-keyboard-importance'>
          <p>
            Why should you invest in a mechanical keyboard? A keyboard is a tool that we use everyday, most people don't even realize how 
            important it is to have a good, working keyboard. If a mechanic buys a cheap wrench, they’ll save money but pay more in the 
            long run when it breaks down and have to get a new one. Now that more people are working from home, it is important to have a 
            dedicated working space that you enjoy so why not personalize the tool you use everyday to exactly how you want it? Some of 
            the reasons why having a mechanical keyboard over any other keyboard include: multiple layout options, improved typing speed, 
            less typos, programmability, ergonomics, and durability.
          </p>
        </Container>
        <Container className='home-video-container'>
          <ReactPlayer url='https://youtu.be/Qr3nYR15wxU' controls className='home-keyboard-video' />
          <span className='home-video-caption'>Video created by Taeha Types.</span>
        </Container>
      </Container>
    );
  }
}

export default HomePage;
