import React, { Component } from 'react';
import '../style/keyboard/Keyboard.css';
import ReactGA from 'react-ga';
import ReactPlayer from 'react-player';
import firebase from 'firebase/app';
import 'firebase/database';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  Icon,
  Container,
  Header,
  Breadcrumb
} from 'semantic-ui-react';
import NotFoundPage from '../pages/NotFoundPage';
import KeyboardImageCarousel from '../components/keyboard/KeyboardImageCarousel';
import KeyboardTile from '../components/keyboard/KeyboardTile';

class KeyboardPage extends Component {
  constructor(){
    super()
      this.state = {
        keyboard: [],
        isLoading: false
      }
  }

  componentWillMount() {
    const { match: { params } } = this.props;

    this.setState({ isLoading: true });

    const rootRef = firebase.database().ref().child(`keyboards/${params.slug}`);
    rootRef.on('value', snapshot => {
      this.setState({
        keyboard: snapshot.val(),
        isLoading: false
      });
      window.scrollTo(0, 0);
    })
  }

  componentDidMount() {
    this.initGA()
    this.logPageView()
  }

  initGA = () => {
    ReactGA.initialize('UA-186178302-1')
  }

  logPageView = () => {
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
  }

  render() {
    const { keyboard, isLoading } = this.state;

    if (!keyboard || keyboard.hidden) {
      return <NotFoundPage />
    };

    if (isLoading) {
      return (
        <div className='keyboard-loading-icon-container'>
          <Icon loading name='keyboard outline' size='huge' className='loading-icon' />
        </div>
      )
    };

    return (
      <Container key={`keyboard ${keyboard.id}`} className='keyboard-container'>
        <Helmet>
          <title>{`${keyboard.name}`} - Saintkeebs</title>
        </Helmet>
        <Container className='breadcrumb-container'>
          <Breadcrumb>
            <Breadcrumb.Section link>
              <Link to='/portfolio' className='keyboard-breadcrumb'>Portfolio</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon='right chevron' />
            <Breadcrumb.Section active>{keyboard.name}</Breadcrumb.Section>
          </Breadcrumb>
        </Container>
        <KeyboardImageCarousel images={keyboard.images} />
        <Container textAlign='center' className='keyboard-header-container'>
          <Header as='h2' className='keyboard-header'>{keyboard.name}</Header>
        </Container>
        {keyboard.macropad === false &&
          <Container className='keyboard-video-container'>
            <span className='keyboard-video-caption'>Sound Test</span>
            <ReactPlayer url={keyboard.sound_test} controls className='keyboard-video' />
          </Container>
        }
        <KeyboardTile keyboard={keyboard} />
      </Container>
    );
  }
}

export default KeyboardPage;

