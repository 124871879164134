import { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Navigation from './components/Navigation';
import HomePage from './pages/HomePage';
import CommissionsPage from './pages/CommissionsPage';
//import ContactPage from './pages/ContactPage';
import PortfolioListPage from './pages/PortfolioListPage';
import KeyboardPage from './pages/KeyboardPage';
import NotFoundPage from './pages/NotFoundPage';
import Footer from './components/Footer';

class App extends Component {
  render() {
    return (
      <Router>
        <>
          <Navigation />
          <Switch>
            <Route exact path='/' render={()=><HomePage />} />
            <Route path='/commissions' render={()=><CommissionsPage />} />
            {/* <Route path='/contact' render={()=><ContactPage />} /> */}
            <Route path='/portfolio' render={()=><PortfolioListPage />} />
            <Route path='/keyboards/:slug' render={(props)=><KeyboardPage {...props} />} />
            <Route path='*' render={()=><NotFoundPage />} />
          </Switch>
          <Footer />
        </>
      </Router>
    );
  }
}

export default App;
