import React, { Component } from 'react';
import '../style/portfolio/Portfolio.css';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import PortfolioFetch from '../components/portfolio/PortfolioFetch';
import { Container, Header } from 'semantic-ui-react';

class PortfolioListPage extends Component {
  componentDidMount() {
    this.initGA()
    this.logPageView()
  }

  initGA = () => {
    ReactGA.initialize('UA-186178302-1')
  }

  logPageView = () => {
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
  }

  render() {
    return (
      <Container className='portfolio-container'>
        <Helmet>
          <title>Portfolio - Saintkeebs</title>
        </Helmet>
        <Container textAlign='center' className='portfolio-header-container'>
          <Header as='h2' className='portfolio-header'>Portfolio</Header>
        </Container>
        <Container textAlign='center' className='portfolio-intro'>
          <p>
            Below you will find a list of all the mechanical keyboards I have built. The keyboards shown range from client builds to 
            personal builds. The list is in order from newest to oldest. Note: any media posted from commission work is done optionally.
          </p>
        </Container>
        <PortfolioFetch />
      </Container>
    );
  }
}

export default PortfolioListPage;
