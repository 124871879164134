import React from 'react';
import '../style/footer/Footer.css';
import Logo from '../images/FooterLogo.png';
import { Container, Image, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className='footer-container'>
      <Container>
        <Container className='footer-logo-container'>
          <Link to='/'>
            <Image size='mini' src={Logo} />
          </Link>
        </Container>
        <h3 className='footer-heading'>Saintkeebs</h3>
        <Container className='footer-link-container'>
          <Link to='/portfolio' className='footer-links'>Portfolio</Link>
          <Link to='/commissions' className='footer-links'>Commissions</Link>
          {/* <Link to='/contact' className='footer-links'>Contact</Link> */}
          <Container className='footer-icon-container'>
            <a href='//instagram.com/saintkeebs/' target='_blank' rel='noreferrer'>
              <Icon name='instagram' size='large' className='footer-social-media-icon' />
            </a>
            <a href='//youtube.com/channel/UCEHQpEcrE58ALQnhKD2JwVQ/featured' target='_blank' rel='noreferrer'>
              <Icon name='youtube' size='large' className='footer-social-media-icon' />
            </a>
            <a href='//discordapp.com/users/7027567872539538453' target='_blank' rel='noreferrer'>
              <Icon name='discord' size='large' className='footer-social-media-icon' />
            </a>
          </Container>
          <span className='footer-copyright'>© 2022 Saintkeebs. All rights reserved.</span>
        </Container>
      </Container>
      <div className='footer-stripe' />
    </footer>
  );
}

export default Footer;
