import React, { Component } from 'react';
import '../style/navigation/Navigation.css';
import Logo from '../images/NavLogo.png';
import { Link } from 'react-router-dom';
import {
  Container,
  Image,
  Menu,
  Visibility,
  Icon
} from 'semantic-ui-react'

class Navigation extends Component {
  constructor(){
    super()
      this.state = {
        menuFixed: false,
        activeItem: ''
      }
  }

  stickTopMenu = () => this.setState({ menuFixed: true })
  unStickTopMenu = () => this.setState({ menuFixed: false })

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    const { menuFixed, activeItem } = this.state

    return (
      <div>
        <Visibility
          onBottomPassed={this.stickTopMenu}
          onBottomVisible={this.unStickTopMenu}
          once={false}
        >
          <Menu
            borderless
            stackable
            fixed={menuFixed ? 'top' : undefined}
            className={menuFixed ? 'fixedMenuStyle' : 'menuStyle'}
          >
            <Container text>
              <Menu.Item as={Link} to='/'>
                <Image size='small' src={Logo} />
              </Menu.Item>
              <Menu.Menu position='right'>
                <Menu.Item
                  as={Link}
                  to='/'
                  className='link item'
                  name='home'
                  active={activeItem === 'home'}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  as={Link}
                  to='/portfolio'
                  className='link item'
                  name='portfolio'
                  active={activeItem === 'portfolio'}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  as={Link}
                  to='/commissions'
                  className='link item'
                  name='commissions'
                  active={activeItem === 'commissions'}
                  onClick={this.handleItemClick}
                />
                {/* <Menu.Item
                  as={Link}
                  to='/contact'
                  className='link item'
                  name='contact'
                  active={activeItem === 'contact'}
                  onClick={this.handleItemClick}
                /> */}
                <Container className='nav-social-media-icon-container'>
                  <Menu.Item href='//instagram.com/saintkeebs/' target='_blank' className='link item'>
                    <Icon name='instagram' size='large' className='nav-social-media-icon' />
                  </Menu.Item>
                  <Menu.Item href='//youtube.com/channel/UCEHQpEcrE58ALQnhKD2JwVQ/featured' target='_blank' className='link item'>
                    <Icon name='youtube' size='large' className='nav-social-media-icon' />
                  </Menu.Item>
                  <Menu.Item href='//discordapp.com/users/7027567872539538453' target='_blank' className='link item'>
                    <Icon name='discord' size='large' className='nav-social-media-icon' />
                  </Menu.Item>
                </Container>
              </Menu.Menu>
            </Container>
          </Menu>
          <div className='nav-stripe' />
        </Visibility>
      </div>
    );
  }
}

export default Navigation;
