import React, { Component } from 'react';
import '../../style/commissions/CommissionsContactSection.css';
import { Container, List } from 'semantic-ui-react';

class CommissionsContactSection extends Component {
  render() {
    return (
      <Container className='contact-info-container'>
        <List className='contact-info-list'>
          <List.Item>
            <List.Icon name='instagram' />
            <List.Content>
              <a href='//instagram.com/saintkeebs/' target='_blank' rel='noreferrer'>
                saintkeebs
              </a>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name='youtube' />
            <List.Content>
              <a href='//youtube.com/channel/UCEHQpEcrE58ALQnhKD2JwVQ/featured' target='_blank' rel='noreferrer'>
                saintkeebs
              </a>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name='discord' />
            <List.Content>
              <a href='//discordapp.com/users/7027567872539538453' target='_blank' rel='noreferrer'>saintkeebs#6612</a>
            </List.Content>
          </List.Item>
        </List>
      </Container>
    );
  }
}

export default CommissionsContactSection;
