import React, { Component } from 'react';
import '../../style/keyboard/KeyboardTile.css';
import { Container, List } from 'semantic-ui-react';

class KeyboardTile extends Component {
  render() {
    const keyboard = this.props.keyboard;

    return (
      <Container className='keyboard-tile-container'>
        <div className='keyboard-tile-one'>
          <h3 className='keyboard-tile-font'>Specifications</h3>
          <Container className='keyboard-spec-container'>
            <div className='keyboard-spec-item'>
              <List>
                <List.Item>
                  <List.Header>Switches</List.Header>
                  {keyboard.switches}
                </List.Item>
                <List.Item>
                  <List.Header>Stabilizers</List.Header>
                  {keyboard.stabilizers}
                </List.Item>
                <List.Item>
                  <List.Header>Lube</List.Header>
                  {keyboard.lube}
                </List.Item>
              </List>
            </div>
            <div className='keyboard-spec-item'>
              <List>
                <List.Item>
                  <List.Header>Keycaps</List.Header>
                  {keyboard.keycaps}
                </List.Item>
                <List.Item>
                  <List.Header>PCB</List.Header>
                  {keyboard.pcb}
                </List.Item>
                <List.Item>
                  <List.Header>Plate</List.Header>
                  {keyboard.plate}
                </List.Item>
              </List>
            </div>
            <div className='keyboard-spec-item'>
              <List>
                <List.Item>
                  <List.Header>Sound Dampening</List.Header>
                  {keyboard.sound_dampening}
                </List.Item>
                <List.Item>
                  <List.Header>Date Built</List.Header>
                  {keyboard.date_built}
                </List.Item>
                <List.Item>
                  <List.Header>Designer</List.Header>
                  {keyboard.designer}
                </List.Item>
              </List>
            </div>
          </Container>
        </div>
      </Container>
    );
  }
}

export default KeyboardTile;
