import React, { Component } from 'react';
import '../../style/commissions/PricingTileSection.css';
import { Container } from 'semantic-ui-react';

class PricingTileSection extends Component {
  render() {
    const { commissionsOpen } = this.props;
    const containerStyle = commissionsOpen ? 'pricing-tile-container' : 'pricing-tile-container-last';

    return (
      <Container className={containerStyle}>
        <div className='tile-one'>
          <h3 className='pricing-tile-font'>Modding</h3>
          <span>
            Stabilizer tuning: <span className='price'>$2.00 per stabilizer</span>, 
            switch modding: <span className='price'>$0.55 per switch</span>, 
            + <span className='price'>$7.00 for films</span> (optional).
          </span>
        </div>
        <div className='tile-two'>
          <h3 className='pricing-tile-font'>Assembly</h3>
          <span>
            Macropads: <span className='price'>$30.00</span>, 
            40% ~ 60%: <span className='price'>$40.00</span>, 
            65% ~ 75%: <span className='price'>$60.00</span>, 
            80% ~ TKL: <span className='price'>$80.00</span>, 
            larger than TKL: <span className='price'>$100.00</span>.
          </span>
        </div>
        <div className='tile-three'>
          <h3 className='pricing-tile-font'>Other</h3>
          <span>
            Basic assembly and soldering or desoldering: it depends, please contact 
            me first.
          </span>
        </div>
      </Container>
    );
  }
}

export default PricingTileSection;
