import React, { Component } from 'react';
import '../../style/keyboard/KeyboardImageCarousel.css';
import { Container } from 'semantic-ui-react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class KeyboardImageCarousel extends Component {
  render() {
    const images = this.props.images;

    return (
      <Container className='keyboard-image-carousel-container'>
        <Carousel
          infiniteLoop
          useKeyboardArrows
          autoPlay
          stopOnHover
          swipeable
          dynamicHeight
        >
          {images.map(image =>
            <img src={image} alt='keyboard-images' />
          )}
        </Carousel>
      </Container>
    );
  }
}

export default KeyboardImageCarousel;