import React, { Component } from 'react';
import '../../style/home/HomeTileSection.css';
import { Container } from 'semantic-ui-react';

class HomeTileSection extends Component {
  render() {
    return (
      <Container className='home-tile-container'>
        <div className='tile-one'>
          <h3 className='home-tile-font'>Layouts</h3>
          <span>
            Mechanical keyboards come in many layouts. They’re generally classified as a percentage of a full-sized keyboard.
          </span>
        </div>
        <div className='tile-two'>
          <h3 className='home-tile-font'>Ergonomics</h3>
          <span>
            Avoid unnecessary strain and fatigue by choosing a mechanical keyboard with lighter switches.
          </span>
        </div>
        <div className='tile-three'>
          <h3 className='home-tile-font'>Durability</h3>
          <span>
            Mechanical keyboards are higher quality than rubber dome and come with switches rated to endure over 50 million presses.
          </span>
        </div>
      </Container>
    );
  }
}

export default HomeTileSection;
