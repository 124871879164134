import React, { Component } from 'react';
import '../style/not-found/NotFound.css';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  Container,
  Header,
  Icon,
  Button
} from 'semantic-ui-react'

class NotFoundPage extends Component {
  componentDidMount() {
    this.initGA()
    this.logPageView()
  }

  initGA = () => {
    ReactGA.initialize('UA-186178302-1')
  }

  logPageView = () => {
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
  }

  render() {
    return (
      <Container className='not-found-container'>
        <Helmet>
          <title>Page Not Found - Saintkeebs</title>
        </Helmet>
        <Container textAlign='center' className='not-found-header-container'>
          <Header as='h2' className='not-found-header'>Page Not Found</Header>
        </Container>
        <Container textAlign='center' className='not-found-url-container'>
          <Icon name='dont' />
          <span>www.saintkeebs.com{window.location.pathname}</span>
        </Container>
        <Container textAlign='center' className='not-found-icon-container'>
          <Icon.Group size='massive'>
            <Icon size='huge' name='close' className='not-found-icon' />
            <Icon size='big' name='keyboard' className='not-found-keyboard-icon' />
          </Icon.Group>
        </Container>
        <Container textAlign='center' className='not-found-navigation-container'>
          <span>The page <q>www.saintkeebs.com{window.location.pathname}</q> doesn't exist or an other error occurred. <br /> Go back or head to our home page below.</span>
          <Button as={Link} to='/' size='large' className='back-button'>Back to Home</Button>
        </Container>
      </Container>
    );
  }
}

export default NotFoundPage;
